<template>
  <div class="group-setting">
    <div class="header pb-3">
      <div class="style-title-setting-global">
        {{ $i18n.locale === 'fr' ? 'Gestion des groupes' : 'Group Management' }}
      </div>
      <div class="message">
        <div v-if="getGroupsError" class="error-msg">
          <ul v-if="Array.isArray(getGroupsError)">
            <li v-for="(e, index) in getGroupsError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getGroupsError }}</span>
        </div>
      </div>
      <v-progress-circular
        indeterminate
        v-if="
          getGroupsLoading &&
            !initLoading &&
            !loading &&
            !loadingUpdate &&
            !loadingOpen
        "
        color="#5C2DD3"
      ></v-progress-circular>
      <v-btn dark color="#5C2DD3" @click.stop="handleClickBtnAddEquipe">
        <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon
        >{{ $i18n.locale === 'fr' ? 'Ajouter un groupe' : 'Add group' }}
      </v-btn>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="header-team mt-3" v-else>
      <v-list
        dense
        class="list-ensemble mt-3"
        v-if="getGroups && getGroups.length"
      >
        <v-list-item
          color="#5C2DD3"
          class="list-ensemble-item mb-3 mr-3"
          v-for="(group, index) in getGroups"
          :key="index"
        >
          <v-list-item-content>
            <v-list-item-title>
              <div
                class="font-text font-sz-14 bold-700 color-black text-capitalize"
              >
                {{ group.name }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              class="icon-action"
              small
              outlined
              color="#5C2DD3"
              :title="$t('update')"
              @click.prevent.stop="handleUpdateClick(group)"
            >
              <font-awesome-icon class="img-h-19" icon="pencil-alt" />
            </v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn
              class="icon-action"
              :title="$t('deleted')"
              small
              outlined
              color="#5C2DD3"
              @click.prevent.stop="handleDeleteClick(group)"
            >
              <font-awesome-icon class="img-h-19" icon="trash-alt" />
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <div v-else class="div-aucun-list">
        <div class="titre">
          {{ $i18n.locale === 'fr' ? 'Aucun groupe' : 'No group' }}
        </div>
      </div>
      <div class="footer-style-table" v-if="getGroups && getGroups.length">
        <div class="text-center pagination-table">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="pagination"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-pagination>
        </div>
        <div class="select-input-vuetify">
          <v-select
            class="per-page-element-style"
            v-model="per_page"
            :items="perPageList"
            @change="changePerPage"
            :label="
              $i18n.locale === 'fr' ? 'Eléments par page' : 'Rows per page'
            "
            outlined
            dense
            hide-details
            :no-data-text="
              $i18n.locale === 'fr' ? 'Aucun élément trouvé' : 'No items found'
            "
            :menu-props="{
              left: true,
              offsetX: true
            }"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-select>
        </div>
      </div>
    </div>
    <!-- Add Group Modal -->
    <v-dialog v-model="modalAddGroup" max-width="1000" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale === 'fr' ? 'Ajouter un groupe' : 'Add group' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('modalAddGroup')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <v-form ref="modalAddGroup" class="mt-2">
            <v-row class="row">
              <v-col>
                <!-- NOM GROUP -->
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="groupeToAdd.name"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>
                <!-- TYPE PROJET -->
                <v-select
                  v-model="groupeToAdd.type_projet"
                  :label="
                    $i18n.locale == 'fr' ? 'Type du projet' : 'Project type'
                  "
                  dense
                  return-object
                  :no-data-text="
                    $i18n.locale == 'fr'
                      ? 'Aucun Type du projet trouvé'
                      : 'None Project type found'
                  "
                  item-text="name"
                  item-value="id"
                  :items="getProjectsTypes"
                  outlined
                  :loading="getProjectsTypesLoading"
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      ($i18n.locale == 'fr'
                        ? 'Type du projet'
                        : 'Project type') +
                        ' ' +
                        $t('msgOblg')
                  ]"
                ></v-select>
                <!-- DESCRIPTION -->
                <v-textarea
                  dense
                  label="Description"
                  v-model="groupeToAdd.description"
                  :persistent-placeholder="true"
                  rows="6"
                  outlined
                  color="#5C2DD3"
                  hide-details=""
                ></v-textarea>
              </v-col>
              <v-col class="border-left-solid">
                <div>
                  <div class="color-black-light mb-2">
                    {{
                      $i18n.locale === 'fr'
                        ? 'Liste admins : '
                        : 'Admins list : '
                    }}
                  </div>
                  <div>
                    <v-autocomplete
                      :clearable="true"
                      color="#5C2DD3"
                      multiple
                      :placeholder="$t('searchMsg', { msg: 'admins' })"
                      item-color="#5C2DD3"
                      v-model="groupeToAdd.admins"
                      :items="users"
                      chips
                      return-object
                      :deletable-chips="true"
                      :small-chips="true"
                      item-text="full_name"
                      dense
                      item-value="id"
                      :no-data-text="
                        $t('noDataOptionMsg', { option: 'admins' })
                      "
                      outlined
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      hide-details=""
                    >
                    </v-autocomplete>
                    <v-divider class="mt-3 mb-3"></v-divider>
                    <div class="color-black-light mb-2">
                      {{
                        $i18n.locale === 'fr'
                          ? 'Liste utilisateurs : '
                          : 'Users list : '
                      }}
                    </div>
                    <div>
                      <v-autocomplete
                        :clearable="true"
                        color="#5C2DD3"
                        multiple
                        :placeholder="
                          $t('searchMsg', { msg: $t('utilisateurs') })
                        "
                        item-color="#5C2DD3"
                        v-model="groupeToAdd.users"
                        :items="users"
                        chips
                        return-object
                        :deletable-chips="true"
                        :small-chips="true"
                        item-text="full_name"
                        dense
                        item-value="id"
                        :no-data-text="
                          $t('noDataOptionMsg', { option: $t('utilisateurs') })
                        "
                        outlined
                        :menu-props="{
                          bottom: true,
                          offsetY: true
                        }"
                        hide-details=""
                      >
                      </v-autocomplete>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getGroupsLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getGroupsError" class="error-msg">
              <ul v-if="Array.isArray(getGroupsError)">
                <li v-for="(e, index) in getGroupsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getGroupsError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSubmit"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('modalAddGroup')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Add Group Modal -->

    <!-- Update Group Modal -->
    <v-dialog v-model="ModalUpdateGroup" max-width="1000" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale === 'fr' ? 'Modifier un groupe' : 'Edit group' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalUpdateGroup')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <div class="loader-content-group" v-if="loadingOpen">
            <v-progress-circular
              indeterminate
              class="ml-3"
              color="#5C2DD3"
            ></v-progress-circular>
          </div>
          <v-form
            ref="ModalUpdateGroup"
            class="mt-2"
            v-if="groupeToUpdate && !loadingOpen"
          >
            <v-row class="row">
              <v-col>
                <!-- NOM Group -->
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="groupeToUpdate.name"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field>
                <!-- TYPE PROJET -->
                <v-select
                  v-model="groupeToUpdate.type_projet"
                  :label="
                    $i18n.locale == 'fr' ? 'Type du projet' : 'Project type'
                  "
                  dense
                  return-object
                  :no-data-text="
                    $i18n.locale == 'fr'
                      ? 'Aucun Type du projet trouvé'
                      : 'None Project type found'
                  "
                  item-text="name"
                  item-value="id"
                  :items="getProjectsTypes"
                  outlined
                  :loading="getProjectsTypesLoading"
                  :persistent-placeholder="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      ($i18n.locale == 'fr'
                        ? 'Type du projet'
                        : 'Project type') +
                        ' ' +
                        $t('msgOblg')
                  ]"
                ></v-select>
                <!-- DESCRIPTION -->
                <v-textarea
                  dense
                  label="Description"
                  v-model="groupeToUpdate.description"
                  :persistent-placeholder="true"
                  rows="6"
                  outlined
                  color="#5C2DD3"
                  hide-details=""
                ></v-textarea>
              </v-col>
              <v-col class="border-left-solid">
                <div>
                  <div class="color-black-light mb-2">
                    {{
                      $i18n.locale === 'fr'
                        ? 'Liste admins : '
                        : 'Admins list : '
                    }}
                  </div>
                  <div>
                    <v-autocomplete
                      :clearable="true"
                      color="#5C2DD3"
                      multiple
                      :placeholder="$t('searchMsg', { msg: 'admins' })"
                      item-color="#5C2DD3"
                      v-model="groupeToUpdate.admins"
                      :items="users"
                      chips
                      return-object
                      :deletable-chips="true"
                      :small-chips="true"
                      item-text="full_name"
                      dense
                      item-value="id"
                      :no-data-text="
                        $t('noDataOptionMsg', { option: 'admins' })
                      "
                      outlined
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      hide-details=""
                    >
                    </v-autocomplete>
                    <v-divider class="mt-3 mb-3"></v-divider>
                    <div class="color-black-light mb-2">
                      {{
                        $i18n.locale === 'fr'
                          ? 'Liste utilisateurs : '
                          : 'Users list : '
                      }}
                    </div>
                    <div>
                      <v-autocomplete
                        :clearable="true"
                        color="#5C2DD3"
                        multiple
                        :placeholder="
                          $t('searchMsg', { msg: $t('utilisateurs') })
                        "
                        item-color="#5C2DD3"
                        v-model="groupeToUpdate.users"
                        :items="users"
                        chips
                        return-object
                        :deletable-chips="true"
                        :small-chips="true"
                        item-text="full_name"
                        dense
                        item-value="id"
                        :no-data-text="
                          $t('noDataOptionMsg', { option: $t('utilisateurs') })
                        "
                        outlined
                        :menu-props="{
                          bottom: true,
                          offsetY: true
                        }"
                        hide-details=""
                      >
                      </v-autocomplete>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getGroupsLoading && !loadingOpen" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getGroupsError" class="error-msg">
              <ul v-if="Array.isArray(getGroupsError)">
                <li v-for="(e, index) in getGroupsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getGroupsError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleSubmitUpdate"
            :loading="loadingUpdate"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalUpdateGroup')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Update Group Modal -->

    <!-- Delete Group Modal -->
    <v-dialog v-model="ModalDeleteGroup" max-width="700" persistent>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale === 'fr' ? 'Supprimer un groupe' : 'Delete group' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalDeleteGroup')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p class="color-black-light">
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? 'le groupe :' : 'the group :'
              })
            }}
            <strong class="text-capitalize">
              {{
                groupeToDelete && groupeToDelete.name ? groupeToDelete.name : ''
              }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getGroupsLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getGroupsError" class="error-msg">
              <ul v-if="Array.isArray(getGroupsError)">
                <li v-for="(e, index) in getGroupsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getGroupsError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleDeletedGroup"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalDeleteGroup')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Delete Group Modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'groupe',
  data() {
    return {
      groupeToAdd: {
        name: null,
        description: null,
        admins: [],
        users: [],
        type_projet: null
      },
      groupeToDelete: null,
      groupeToUpdate: null,
      loading: false,
      loadingUpdate: false,
      loadingOpen: false,
      initLoading: true,
      modalAddGroup: false,
      ModalDeleteGroup: false,
      ModalUpdateGroup: false,
      page: 1,
      per_page: 10,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 },
        { value: 50, text: 50 }
      ]
    }
  },
  methods: {
    ...mapActions([
      'fetchAllGroups',
      'fetchOneGroups',
      'addGroup',
      'updateGroup',
      'deletedGroup',
      'resetErrorGroup',

      'deletedAdmins',
      'deletedUsers',
      'getUsers',

      'fetchProjetsTypes'
    ]),
    pagination(pagination) {
      this.page = pagination
      this.fetchAllGroups({
        page: this.page,
        per_page: this.per_page
      })
    },
    changePerPage() {
      this.fetchAllGroups({
        page: this.page,
        per_page: this.per_page
      })
    },
    handleClickBtnAddEquipe() {
      this.modalAddGroup = true
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'modalAddGroup') {
        this.$refs.modalAddGroup.resetValidation()
      }
      if (ref == 'ModalUpdateGroup') {
        this.$refs.ModalUpdateGroup.resetValidation()
      }
      this.resetModal()
    },
    async handleDeletedGroup() {
      this.loading = true
      const response = await this.deletedGroup(this.groupeToDelete.id)
      if (response) {
        this.closeDialog('ModalDeleteGroup')
        this.fetchAllGroups({ page: this.page, per_page: this.per_page })
      }
      this.loading = false
    },
    handleDeleteClick(group) {
      this.groupeToDelete = group
      this.ModalDeleteGroup = true
    },

    async handleUpdateClick(group) {
      this.loadingOpen = true
      this.ModalUpdateGroup = true
      await this.fetchOneGroups(group.id)
      this.groupeToUpdate = this.getOneGroup ? this.getOneGroup : { ...group }
      this.groupeToUpdate.type_projet = this.groupeToUpdate.type_projet_id
      this.loadingOpen = false
    },
    resetModal() {
      this.groupeToAdd = {
        name: null,
        description: null,
        admins: [],
        users: [],
        type_projet: null
      }
      this.loading = false
      this.loadingUpdate = false
      this.resetErrorGroup()
    },
    async handleSubmit() {
      if (this.$refs.modalAddGroup.validate()) {
        this.loading = true
        const body = {
          name: this.groupeToAdd.name,
          description: this.groupeToAdd.description,
          users:
            this.groupeToAdd.users && this.groupeToAdd.users.length
              ? this.groupeToAdd.users.map(item => item.id)
              : [],
          admins:
            this.groupeToAdd.admins && this.groupeToAdd.admins.length
              ? this.groupeToAdd.admins.map(item => item.id)
              : [],
          type_projet_id:
            this.groupeToAdd &&
            this.groupeToAdd.type_projet &&
            this.groupeToAdd.type_projet.id
              ? this.groupeToAdd.type_projet.id
              : this.groupeToAdd.type_projet
        }
        const response = await this.addGroup(body)
        if (response) {
          this.closeDialog('modalAddGroup')
          this.fetchAllGroups({ page: this.page, per_page: this.per_page })
        }
        this.loading = false
      }
    },
    async handleSubmitUpdate() {
      if (this.$refs.ModalUpdateGroup.validate()) {
        this.loadingUpdate = true
        const body = {
          name: this.groupeToUpdate.name,
          description: this.groupeToUpdate.description,
          users:
            this.groupeToUpdate.users && this.groupeToUpdate.users.length
              ? this.groupeToUpdate.users.map(item => item.id)
              : [],
          admins:
            this.groupeToUpdate.admins && this.groupeToUpdate.admins.length
              ? this.groupeToUpdate.admins.map(item => item.id)
              : [],
          type_projet_id:
            this.groupeToUpdate &&
            this.groupeToUpdate.type_projet &&
            this.groupeToUpdate.type_projet.id
              ? this.groupeToUpdate.type_projet.id
              : this.groupeToUpdate.type_projet
        }
        const response = await this.updateGroup({
          body,
          group: this.groupeToUpdate
        })
        if (response) {
          this.loadingUpdate = false
          this.closeDialog('ModalUpdateGroup')
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'getGroups',
      'getGroupsLoading',
      'getGroupsError',
      'users',
      'getOneGroup',
      'getProjectsTypes',
      'getProjectsTypesLoading',
      'countPaginationGroupe'
    ]),
    totalPages() {
      if (this.countPaginationGroupe) {
        return Math.ceil(this.countPaginationGroupe / this.per_page)
      }
      return this.countPaginationGroupe
    }
  },
  async mounted() {
    await this.fetchAllGroups({ page: this.page, per_page: this.per_page })
    this.getUsers({ exclude: ['team.manager', 'team.depots', 'visibleTeams'] })
    this.fetchProjetsTypes()
    this.initLoading = false
  }
}
</script>

<style lang="scss" scoped>
.group-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      height: 30px;
      min-width: 30px;
    }
  }
  .list-ensemble-item {
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-color: #d8d8d8 !important;
  }

  .list-ensemble {
    max-height: calc(100vh - 286px) !important;
    height: calc(100vh - 286px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .list-ensemble::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .list-ensemble::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .list-ensemble::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
}
</style>

<style lang="scss">
.group-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }
  }
}
.loader-content-group {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}
</style>
